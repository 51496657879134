<template>
    <div>
        <i class="fi fi-rr-clock-three mr-1 text-xs"></i>
        {{ durationFormat(text) }}
    </div>
</template>

<script>
const durationFormat = (duration) => {
    const getTimeFromMins = (mins) => {
        if (mins >= 24 * 60 || mins < 0) {
            throw new RangeError("Valid input should be greater than or equal to 0 and less than 1440.");
        }
        const h = mins / 60 | 0,
            m = mins % 60 | 0,
            times = moment.utc().hours(h).minutes(m)

        if(times.format("mm") === '00') {
            return times.format(`HH ${declOfNum(Number(times.format(`HH`)),
                ['час', 'часа', 'часов'])}`)
        } else {
            return times.format(`HH ${declOfNum(Number(times.format(`HH`)), ['час', 'часа', 'часов'])} mm ${declOfNum(Number(times.format(`mm`)), ['минута', 'минуты', 'минут'])}`)
        }
    }

    if(duration > 59) {
        return getTimeFromMins(duration)
    } else {
        return `${duration} ${declOfNum(duration, ['минута', 'минуты', 'минут'])}`
    }
}
export default {
    props: {
        text: {
            type: [Object, Boolean, String, Number]
        },
    },
    methods: {
        durationFormat,
        
        // dFormat(duration) {
        //     return durationFormat(duration)
        // },
    }
}
</script>